<template>
  <div class="flex items-center static">
    <router-link class="inline-flex items-center absolute" :to="{ name: 'home' }">
      <i class="tmlps-i-arrow-left text-5xl"></i>
      <img
        :src="tenant.logo_url ? tenant.logo_url : `${publicPath}img/logo-placeholder.png`"
        class="h-8 w-8 border ml-2"
        :alt="tenant.name"
      />
      <span class="font-bold ml-2">{{ tenant.name }}</span>
    </router-link>

    <nav class="flex-grow py-3">
      <ul class="flex justify-center space-x-16">
        <li>
          <router-link
            :to="{ name: 'tenant-home' }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <a class="block" :href="href" @click="navigate">
              <span class="px-2" :class="{ 'font-bold': isExactActive }">Proyectos</span>
              <div v-show="isExactActive" class="h-1 bg-primary-500 rounded-t-sm mt-px"></div>
            </a>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'tenant-users' }"
            custom
            v-slot="{ href, route, navigate, isExactActive }"
          >
            <a class="block" :href="href" @click="navigate">
              <span class="px-2" :class="{ 'font-bold': isExactActive }">Usuarios</span>
              <div v-show="isExactActive" class="h-1 bg-primary-500 rounded-t-sm mt-px"></div>
            </a>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'tenant-profile' }"
            custom
            v-slot="{ href, route, navigate, isExactActive }"
          >
            <a class="block" :href="href" @click="navigate">
              <span class="px-2" :class="{ 'font-bold': isExactActive }">Perfil</span>
              <div v-show="isExactActive" class="h-1 bg-primary-500 rounded-t-sm mt-px"></div>
            </a>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'TenantSubNav',
  props: {
    tenant: { type: Object, required: true },
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
};
</script>
