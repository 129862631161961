<template>
  <AppLayout>
    <template v-slot:sub-nav>
      <SubNav>
        <TenantSubNav :tenant="tenant.current" />
      </SubNav>
    </template>

    <router-view />
  </AppLayout>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import AppLayout from '@/layouts/AppLayout.vue';
import TenantSubNav from '@/components/TenantSubNav.vue';
import SubNav from '@/components/SubNav.vue';

export default {
  name: 'TenantHome',
  components: { AppLayout, SubNav, TenantSubNav },
  computed: {
    ...mapState(['tenant']),
  },

  /**
   * LIFECYCLE HOOKS
   */
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(to, next) {
  await store.dispatch('tenant/fetchTenant', to.params.id);
  next();
}
</script>
